import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { MatToolbarModule } from "@angular/material/toolbar";

import { TranslateModule } from "@ngx-translate/core";

import { MenuComponent } from './menu.component';
import { MenuToolbarComponent } from './toolbar/menu-toolbar.component';
import { LocalActionsModule } from '../local-actions/local-actions.module';
import { FiltersModule } from '../filters/filters.module';
import { MenuOrderPipe } from '../../shared/pipes/menu-order/menu-order.pipe';
import { ReactiveFormsModule } from "@angular/forms";
import { AngularMaterialModule } from "../../angular-material/angular-material.module";
import { PipesModule } from "../../shared/pipes/pipes.module";
import { FormModule } from "../../shared/components/default/form/form.module";
import { DirectivesModule } from "../../shared/directives/directives.module";
import { AvatarModule } from "../../shared/components/default/avatar/avatar.module";
import { CopyrightModule } from "../../shared/components/default/copyright/copyright.module";
import { ProgressSpinnerModule } from "../../shared/components/default/progress-spinner/progress-spinner.module";
import { NoResultsModule } from "../../shared/components/default/no-results/no-results.module";

import { FormPipeModule } from "../../shared/pipes/form/form-pipe.module";

@NgModule({
    declarations: [
        MenuComponent,
        // MenuBreadcrumbComponent,
        MenuToolbarComponent,
        MenuOrderPipe,

    ],
    imports: [
        CommonModule,
        AngularMaterialModule,
        RouterModule,
        PipesModule,
        FormModule,
        DirectivesModule,
        LocalActionsModule,
        FiltersModule,
        // LinkModule,
        TranslateModule,
        AvatarModule,
        CopyrightModule,
        ProgressSpinnerModule,
        NoResultsModule,
        MatToolbarModule,
        // SkeletonLoadingModule,
        ReactiveFormsModule,
        FormPipeModule,
        // ButtonActionModule,
        // FormPipeModule
    ],
    exports: [
        MenuComponent,
        // MenuBreadcrumbComponent,
        MenuToolbarComponent
    ]
})
export class MenuModule{}
