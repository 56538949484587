import { Component, OnInit } from '@angular/core';
import { Observable, Subject } from "rxjs";
import {
	debounceTime,
	distinctUntilChanged,
	map,
	startWith,
	switchMap,
	takeUntil,
	tap,
	withLatestFrom
} from "rxjs/operators";
import { FormBuilder, FormControl, FormGroup } from "@angular/forms";
import {
	filterArray,
	filterNotEmptyArrays,
	filterTruthy,
	fromControlsValue,
	subscribeOnce
} from "../../../shared/rxjs-operators";
import { BaseSubscriber } from "../../../shared/base/base-subscriber";
import { MatDatepicker, MatDateRangePicker } from "@angular/material/datepicker";
import { FormChangeDetector } from "../../../shared/rxjs-operators/interfaces/form-change-detector";
import { Translate } from "../../../shared/helpers/translate";
import { FormButton } from "../../../shared/models/form-button";
import { Auxiliary } from "../../../shared/helpers/auxiliary";
import { Moment } from "../../../shared/helpers/moment";
import { Generic } from "../../../shared/models/generic";
import { FilterField } from "../interfaces/filter-field";
import { FiltersService } from "../filters.service";
import { Filter } from "../interfaces/filter";

@Component({
	selector: 'app-quick-filters',
	templateUrl: './quick-filters.component.html',
	styleUrls: ['./quick-filters.component.scss']
})
export class QuickFiltersComponent extends BaseSubscriber implements OnInit {
	fields$: Observable<FilterField[]>;
	form$: Observable<FormGroup | null>;
	canShow$ = this.filtersService.canShowFilter$;
	canShowClear$: Observable<boolean>;
	position$: Observable<'flex-start' | 'flex-end'>;

	clearAction = Translate.value("filters.actions.clear.text");

	clear: FormButton = {
		color: "primary",
		theme: "stroked",
		icon: "filter_list_off",
		tooltip: this.clearAction,
		text: this.clearAction,
		condition: () => true
	};

	private filter$: Observable<Filter> = this.filtersService.filter$;

	constructor(
		private fb: FormBuilder,
		private filtersService: FiltersService,
	) {
		super();
		this.fields$ = this.filter$.pipe(
			map(filter => filter.fields),
			filterArray(filter => !!filter.showOnQuickFilters),
			tap(filters => {
				if (filters.length > 3)
					console.warn("Inline filters cannot have more than 3 filters, " +
						"only the first 3 will be displayed");
			}),
			map(filters => filters.slice(0, 3)),
			startWith([])
		);

		this.form$ = this.filter$.pipe(
			map(filter => filter.form),
			startWith(null)
		);

		this.canShowClear$ = this.filter$.pipe(
			map(filter => filter?.quickFilter),
			map(quick => {
				if('showClearButton' in (quick ?? {})) return !!quick?.showClearButton;
				else return true;
			}),
			startWith(true)
		);

		this.position$ = this.filter$.pipe(
			map(filter => filter?.quickFilter),
			map(quick => quick?.position === 'right' ? 'flex-end' : 'flex-start')
		);
	}

	ngOnInit() {
		const autocompleteDetectors = (fields: FilterField[]) => fields.filter(field => !!field.name).map((field): FormChangeDetector => {
			const detector = (prev: any, curr: any) => {
				const isNumber = Auxiliary.isNumber(curr);

				if (!isNumber) return true;

				return prev === curr;
			};

			return {
				controlName: field.name ?? '',
				detector
			};
		});

		const searchOnFormChange$ = this.form$.pipe(
			filterTruthy(),
			withLatestFrom(this.fields$),
			switchMap(([form, fields]) => {
				const names = fields.map(field => field.name!);
				const detectors = autocompleteDetectors(fields);

				return fromControlsValue(names, form, detectors);
			}),
			debounceTime(500),
			distinctUntilChanged((prev, curr) => JSON.stringify(prev) === JSON.stringify(curr)),
			switchMap(() => this.fields$),
			filterNotEmptyArrays(),
			tap(() => {
				this.filtersService.search()
			})
		);

		this.subscribe(searchOnFormChange$);
	}

	onMonthSelect({datepicker, dateRange, normalizedMonth}: {
		normalizedMonth: Moment;
		datepicker?: MatDatepicker<any>;
		dateRange?: MatDateRangePicker<any>;
		date: FormControl;
	}, field: FilterField) {
		if (!field.closeOnMonthSelect) return;
		const ctrlValue = Moment.moment();

		//@ts-ignore
		ctrlValue.month(normalizedMonth.month());
		//@ts-ignore
		ctrlValue.year(normalizedMonth.year());

		const form = subscribeOnce(this.form$);

		form?.get(field.name ?? '')?.setValue(ctrlValue.toDate(), {emitEvent: false});

		datepicker?.close();
		dateRange?.close();
	}

	onLoadOptions(options: {
		options: any[];
		filteredOptions: Observable<Generic[]>;
	}, currentFilter: FilterField) {
		const loaded$ = new Subject();

		const loadOptions$ = this.form$.pipe(
			filterTruthy(),
			takeUntil(loaded$),
			tap(form => {
				const control = form.get(currentFilter.name ?? '');

				currentFilter?.onLoadOptions?.(
					options?.options ?? [],
					control as FormControl
				);

				loaded$.next(null);
				loaded$.complete();
			})
		);

		this.subscribe(loadOptions$);
	}

	clearFilters() {
		this.filtersService.clearAll();
	}
}
