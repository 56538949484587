export const PASSWORD_EDIT_TRANSLATE = {
  passwordEdit: {
    messages: {
      error: "Ocorreu um erro ao alterar a senha"
    },
    back: "Voltar",
    form: {
      title: "Alterar senha",
      fields: {
        password: {
          label: "Nova senha",
          placeholder: "Informe a nova senha",
          hint: "A senha deve conter caracteres especiais, maiúsculos, minúsculos e números"
        },
        passwordConfirmation: {
          label: "Confirmação de nova senha",
          placeholder: "Informe a confirmação de nova senha",
          hint: "A confirmação de senha deve conter caracteres especiais, maiúsculos, minúsculos e números"
        }
      },
      actions: {
        save: {
          text: "Alterar"
        }
      }
    }
  }
}
