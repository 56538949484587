import { AppointmentsStatus } from "../../modules/appointments/enums/appointments-status";

export const APPOINTMENTS_TRANSLATE = {
    appointments: {

        messages: {
            performed: "Ação realizada com sucesso",
            notAttended: "Ação realiada com sucesso",
            billed: "Ação realidade com sucesso"
        },

        list: {
            columns: {
                appointmentAt: "Horário",
                patientName: "Paciente",
                employeeName: "Colaborador",
                account: "Empresa",
                status: "Situação",
                headquarter: "Unidade",
                professionalName: "Profissional"
            },
            dialogs: {
                performed: {
                    title: "Confirmar atendimento",
                    body: "Confirmar que esta consulta foi atendida?",
                    actions: {
                        back: "Voltar",
                        confirm: "Sim, confirmar"
                    }
                },

                billed: {
                    title: "Marcar consulta como faturada",
                    body: "Confirmar que esta consulta está faturada?",
                    actions: {
                        back: "Voltar",
                        confirm: "Sim, confirmar"
                    }
                },
                notAttended: {
                    title: "Não compareceu!",
                    body: "Confirmar que este paciente não compareceu a consulta?",
                    actions: {
                        back: "Fechar",
                        confirm: "Sim, confirmar"
                    }
                }
            },
            actions: {
                performed: {
                    label: "Cofirmar atendimento"
                },
                billed: {
                    label: "Marcar como faturada"
                },
                notAttended: {
                    label: "Não compareceu"
                },
                report: {
                    label: "Ver guia"
                }

            },

            filters: {
                fields: {
                    status: {
                        url: "situacao",
                        options: {
                            [AppointmentsStatus.SCHEDULED]: "Agendadas",
                            [AppointmentsStatus.PERFORMED]: "Atendidas",
                            [AppointmentsStatus.CANCELED]: "Canceladas",
                            [AppointmentsStatus.BILLED]: "Faturadas",
                            [AppointmentsStatus.NOT_ATTENDED]: "Não compareceram",
                            [AppointmentsStatus.ALL]: "Todas"
                        }
                    },
                    appointmentPeriod: {
                        label: "Período da consulta",
                        startUrl: "start-appointment-date",
                        endUrl: "end-appointment-date"
                    },
                    patientName: {
                        label: "Paciente",
                        placeholder: "Informe o paciente",
                        url: "paciente"
                    },
                    professionalName: {
                        label: "Profissional",
                        placeholder: "Informe o profissional",
                        url: "profissional"
                    },
                    accountName: {
                        label: "Empresa",
                        placeholder: "Informe a empresa",
                        url: "empresa"
                    },
                    headquarterName: {
                        label: "Unidade",
                        placeholder: "Informe a unidade",
                        url: "unidade"
                    },
                }
            }
        }

    }
}
