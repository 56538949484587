import { Component } from '@angular/core';

import { Authentication } from "./core/authentication/authentication";
import { SignInService } from "./modules/sign-in/sign-in.service";

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent{
    title = 'web';

    authentication = Authentication;

    constructor(
        public signInService: SignInService
    ) {

    }
}
