import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InitDirective } from "./init/init.directive";
import { ActionDirective } from "./action/action.directive";
import { AnchorDirective } from "./anchor/anchor.directive";
import { AutofocusDirective } from "./autofocus/autofocus.directive";
import { VarDirective } from "./var/var.directive";
import { KeyboardManagerItemDirective } from "./keyboard-manager/keyboard-manager-item.directive";
import { KeyboardManagerDirective } from "./keyboard-manager/keyboard-manager.directive";
import { DisableDirective } from "./disable/disable.directive";



@NgModule({
  declarations: [
    InitDirective,
    ActionDirective,
    AnchorDirective,
    AutofocusDirective,
    VarDirective,
    KeyboardManagerDirective,
    KeyboardManagerItemDirective,
    DisableDirective
  ],
  imports: [
    CommonModule
  ],
  exports: [
    InitDirective,
    ActionDirective,
    AnchorDirective,
    AutofocusDirective,
    VarDirective,
    KeyboardManagerDirective,
    KeyboardManagerItemDirective,
    DisableDirective
  ]
})
export class DirectivesModule { }
