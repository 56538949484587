import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule } from "@angular/common/http";

import { TranslateModule } from "@ngx-translate/core";
import { StoreModule } from '@ngrx/store';
import { StoreRouterConnectingModule } from '@ngrx/router-store';
import { EffectsModule } from '@ngrx/effects';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AngularMaterialModule } from "./angular-material/angular-material.module";
import { Translate } from "./shared/helpers/translate";
import { NoResultsModule } from "./shared/components/default/no-results/no-results.module";
import { PipesModule } from "./shared/pipes/pipes.module";
import { GlobalLoadingModule } from "./shared/components/default/global-loading/global-loading.module";
import { ErrorsModule } from "./core/errors/errors.module";
import { AppRequestsModule } from "./app-requests.module";
import { MenuModule } from "./core/menu/menu.module";
import { productionReducer as appReducer } from './shared/store/reducers';

@NgModule({
    declarations: [
        AppComponent
    ],
    imports: [
        TranslateModule.forRoot(Translate.configurations),
        BrowserModule.withServerTransition({ appId: 'serverApp' }),
        AppRoutingModule,
        BrowserAnimationsModule,
        AngularMaterialModule,
        TranslateModule,
        NoResultsModule,
        PipesModule,
        StoreModule.forRoot({app: appReducer}),
        GlobalLoadingModule,
        HttpClientModule,
        ErrorsModule,
        AppRequestsModule,
        StoreRouterConnectingModule.forRoot(),
        EffectsModule.forRoot([]),
        MenuModule,

    ],
    providers: [],
    bootstrap: [AppComponent]
})
export class AppModule {
}
