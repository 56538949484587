<mat-form-field class="chips"
                [class.chips--has-prefix]="!!imageProperty || icon"
                [class.chips--input-disabled]="inputDisabled"
                [formGroup]="form"
                [hideRequiredMarker]="inputDisabled"
                [appearance]="appearance"
                [floatLabel]="floatLabel">
    <mat-label *ngIf="label">{{ label | translate }}</mat-label>
    <mat-chip-list #chips
                   [required]="required"
                   [formControlName]="name"
                   [attr.aria-label]="label | translate">
        <mat-chip *ngFor="let item of selectedObjects()"
                  class="chips__chip"
                  [selectable]="selectable"
                  [color]="color"
                  [removable]="removable"
                  (removed)="remove(type === enum.AUTOCOMPLETE ? item[sendProperty] : item)">
            <mat-icon aria-hidden="true"
                      class="chips__icon"
                      *ngIf="((item && item[iconProperty]) || icon) && (!imageProperty || item.errorImageProperty)">
                {{ (item && item[iconProperty]) || icon }}
            </mat-icon>

            <img class="chips__img"
                 *ngIf="item && !!imageProperty && !item.errorImageProperty"
                 (error)="item.errorImageProperty = true"
                 [src]="!item.errorImageProperty && enviroment.api + setImage(item)"
                 [alt]="(item && item[nameProperty]) | translate">

            <span>{{ type === enum.INPUT ? (item | translate) : ((item && item[nameProperty]) | translate) }}</span>

            <mat-icon matChipRemove *ngIf="removable" [attr.aria-label]="'form.chips.remove' | translate">cancel</mat-icon>
        </mat-chip>
        <input [placeholder]="field.disabled ? null : placeholder | translate"
               #formInput
               [disabled]="inputDisabled ? true : null"
               [name]="name"
               autocomplete="off"
               [type]="inputType"
               [attr.inputmode]="inputMode"
               aria-label=""
               [appAutofocus]="autofocus"
               [attr.aria-label]="label | translate"
               (focus)="onFocus()"
               (input)="onInput()"
               #trigger="matAutocompleteTrigger"
               [matAutocomplete]="autocomplete"
               [matChipInputFor]="chips"
               [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
               [matChipInputAddOnBlur]="addOnBlur"
               (matChipInputTokenEnd)="add($event)">
        <mat-autocomplete #autocomplete="matAutocomplete" (optionSelected)="add($event)">
            <div *ngIf="service && filteredOptions">
                <div *ngIf="isLoading || !(filteredOptions | async)?.length">
                    <mat-option disabled="true">
                        <mat-spinner mode="indeterminate"
                                     *ngIf="isLoading; else notFound"
                                     class="chips__loading"
                                     [strokeWidth]="3"
                                     [diameter]="30">
                        </mat-spinner>
                        <ng-template #notFound>{{ 'form.chips.notFound' | translate }}</ng-template>
                    </mat-option>
                </div>
                <div *ngIf="!isLoading && (filteredOptions | async)?.length">
                    <mat-option *ngFor="let option of filteredOptions | async"
                                [value]="type === enum.AUTOCOMPLETE ? option[sendProperty] : option[nameProperty]"
                                (onSelectionChange)="openPanel($event, trigger)"
                                (click)="openPanel($event, trigger)">
                        {{ option[nameProperty] | translate }}
                    </mat-option>
                </div>
            </div>
        </mat-autocomplete>
    </mat-chip-list>
    <mat-hint *ngIf="hint">{{ hint | translate }}</mat-hint>
    <mat-error *ngIf="!inputDisabled && formsHelper.isInvalidField(field)">{{ (formsHelper.getErrorMessage(field) | async) }}</mat-error>
</mat-form-field>
