import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FilterPipe } from "./filter/filter.pipe";
import { SafeUrlPipe } from "./safe-url/safe-url.pipe";
import { CurrencyFormatPipe } from "./currency-format/currency-format.pipe";
import { FormPipeModule } from "./form/form-pipe.module";


@NgModule({
    declarations: [
        FilterPipe,
        SafeUrlPipe,
        CurrencyFormatPipe,
    ],
    imports: [
        CommonModule,
        FormPipeModule
    ],
    exports: [
        FilterPipe,
        SafeUrlPipe,
        CurrencyFormatPipe,
    ]
})
export class PipesModule {
}
