import { ChangeDetectorRef, Component, ElementRef, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FloatLabelType, MatFormFieldAppearance } from '@angular/material/form-field';
import { AbstractControl, FormControl, FormGroup } from "@angular/forms";

import { Subscription } from "rxjs";
import { CurrencyMaskConfig, CurrencyMaskInputMode } from "ngx-currency";
import { pairwise, startWith } from "rxjs/operators";

import { FormService } from "../form.service";
import { Generic } from "../../../../models/generic";
import { Auxiliary } from "../../../../helpers/auxiliary";

@Component({
    selector: 'app-form-input',
    templateUrl: './form-input.component.html',
    styleUrls: ['./form-input.component.scss'],
    preserveWhitespaces: false
})
export class FormInputComponent implements OnInit, OnDestroy{
    @ViewChild('formInputMoney') formInputMoney: ElementRef;
    @ViewChild('formInputDefault') formInputDefault: ElementRef;
    @ViewChild('formInputMask') formInputMask: ElementRef;
    @Input() label = '';
    @Input() type = '';
    @Input() appearance: MatFormFieldAppearance = 'outline';
    @Input() placeholder = '';
    @Input() icon = '';
    @Input() init: any;
    @Input() hint = '';
    @Input() hintLabel = '';
    @Input() suffix = '';
    @Input() maxlength: number;
    @Input() minlength: number;
    @Input() required: boolean;
    @Input() autofocus = false;
    @Input() loading: boolean;
    @Input() uppercase: boolean;
    @Input() inputMode = '';
    @Input() selectAll: boolean;
    @Input() floatLabel: FloatLabelType = 'auto';
    @Input() name = '';
    @Input() leadZeroDateTime = false;
    @Input() isMoney = false;
    @Input() moneyPrefix = 'R$';
    @Input() dropSpecialCharacters: boolean|string[] = true;
    setMaskAsPristineOnInit = true;
    mask = '';
    field: FormControl;
    form: FormGroup;
    formsHelper = FormService;
    hide = true;

    moneyOptions: CurrencyMaskConfig = {
        align: "right",
        allowNegative: true,
        allowZero: true,
        decimal: ",",
        precision: 2,
        prefix: "",
        suffix: "",
        thousands: ".",
        nullable: true,
        min: undefined,
        max: undefined,
        inputMode: CurrencyMaskInputMode.FINANCIAL
    };

    @Input('form')
    set setForm(form: FormGroup|AbstractControl){
        this.form = form as FormGroup;
    }

    @Input('moneyOptions')
    set setMoneyOptions(moneyOptions: CurrencyMaskConfig|Generic){
        if(moneyOptions) this.moneyOptions = Auxiliary.assignAllObjects(this.moneyOptions, moneyOptions) as CurrencyMaskConfig;
    }

    @Input('mask')
    set setMask(mask: string){
        if(mask) this.mask = mask;
    }

    private subscriptions: Subscription[] = [];

    constructor(private changeDetect: ChangeDetectorRef){}

    ngOnInit(): void{
        this.field = this.formsHelper.getField(this.form as FormGroup, this.name);
        this.required = Auxiliary.isBoolean(this.required) ? this.required : this.formsHelper.getRequired(this.field);

        this.watchFieldChange();
        this.disableSetPristineField();
        this.setInputMode();

        if(!Auxiliary.isUndefined(this.init)) setTimeout(() => this.field?.setValue(this.init));

        this.changeDetect.detectChanges();
    }

    showMoneyPrefix(): boolean{
        return this.isMoney && (this.formInputMoney?.nativeElement === document?.activeElement || this.field?.value);
    }

    showLengthField(): number{
        return this.formInputDefault?.nativeElement?.value?.length || this.formInputMoney?.nativeElement?.value?.length || 0;
    }

    toggleHide(evt: MouseEvent|KeyboardEvent): void{
        evt.preventDefault();
        evt.stopPropagation();

        this.hide = !this.hide;
    }

    isPassowordType(type: string): boolean{
        return type === 'password';
    }

    getType(type: string): string{
        return !this.hide && this.isPassowordType(type) ? 'text' : type;
    }

    onFocus(event: FocusEvent): void{
        if(this.isPassowordType(this.type) || this.selectAll){
            const input = (event?.target || event?.currentTarget) as HTMLInputElement;

            input?.select();
        }
    }

    setInputMode(): void{
        if(!this.inputMode){
            if(this.type === 'email') this.inputMode = 'email';
            else if(this.type === 'search') this.inputMode = 'search';
            else if(this.type === 'tel') this.inputMode = 'tel';
            else if(this.type === 'url') this.inputMode = 'url';
            else if(this.isMoney) this.inputMode = 'decimal';
            else if(this.type === 'number' || this.mask) this.inputMode = 'numeric';
            else this.inputMode = 'text';
        }
    }

    upperCase(input: HTMLInputElement): void{
        if(this.uppercase) input.value = input.value.toUpperCase();
    }

    ngOnDestroy(): void{
        Auxiliary.unsubscribeAll(this.subscriptions);
    }

    onKeypress(event: KeyboardEvent): void{
        const currentTarget = event?.currentTarget as HTMLInputElement;
        const target = event?.target as HTMLInputElement;

        if(this.mask && ((currentTarget && !currentTarget.value) || (target && !target.value)) && !this.leadZeroDateTime){
            const firstChatMask = this.mask.replace(/\W/g, '')[0];
            const value = event.key;

            if(Auxiliary.isNumber(firstChatMask) && !Auxiliary.isNumber(value)) event.preventDefault();
            else if(Auxiliary.isAlphabetLetter(firstChatMask) && !Auxiliary.isAlphabetLetter(value)) event.preventDefault();
        }
    }

    private watchFieldChange(): void{
        this.subscriptions.push(
            this.field
                ?.valueChanges
                .pipe(startWith(null), pairwise())
                .subscribe(([previousValue, currentValue]) => {
                    if(this.isMoney && currentValue === 0) this.field?.setValue(null);
                    if(this.setMaskAsPristineOnInit && this.mask && previousValue === currentValue) this.setPristineFieldOnInit();
                }) as Subscription
        );
    }

    private disableSetPristineField(): void{
        setTimeout(() => this.mask ? this.setPristineFieldOnInit() : null, 1000);
    }

    private setPristineFieldOnInit(): void{
        this.setMaskAsPristineOnInit = false;
        this.field?.markAsPristine();
    }
}
