import { ACTIONS_TRANSLATE } from "./actions";

export const GENERIC_TRANSLATIONS: any = {
	root: {
		language: "pt-BR",
		noscript: "Para visualizar essa aplicação, habilite o JavaScript, por favor.",
		title: "{{ projectName }}",
		metas: {
			description: `Você quer informação, quer um time organizado, produtivo e motivado.
			Sua empresa precisa do JoinIn. Uma plataforma de produtividade e conteúdo
			que facilita processos, e agiliza a comunicação com os times.`,
			keywords: `JoinIn, ferramentas, espaços, interação, comunicação, relações,
			publicações, contato, informação, organização, produtivo, motivado,
			processos, agiliza, times, estrutura dinâmica, customizável, serviços,
			auto serviço, recursos humanos, mural digital, rede social, rede social
			corporativa, central de serviço, viagens, digital, intranet, integração,
			aprovações, solicitações, pessoas, transformação, descentralização, desburocratização,
			estratégias, negócios`,
			author: "JoinIn",
			copyright: "JoinIn"
		},
		workWithUs: {
			first: "Time do JoinIn por aqui!",
			second: "Trabalhe conosco: http://joinin.com.br.",
			third: "#wearejoinin"
		}
	},

    generic: {
        ...ACTIONS_TRANSLATE,
        noResults: {
            label: "Nenhuma informação foi adicionada",
            description: "Quando forem adicionadas, aparecerão aqui.",
            filter: "Nada foi encontrado para a sua pesquisa",
            message: "Altere seus filtros e tente novamente",
        },
    },

	boxInfo: {
		user: {
			registration: "Matrícula: {{identificationNumber}}",
			admission: "Admissão: {{admissionDate}}",
			jobTitle: "Cargo",
			department: "Área",
			finishedAt: "Finalizado em",
			manager: "Gestor",
		}
	},

    situations: {
        active: "Ativos",
        activeSingular: "Ativo",
        inactive: "Inativos",
        inactiveSingular: "Inativo",
        all: "Todos",
        allFemale: "Todas",
        ratings: {
            one: "1",
            two: "2",
            three: "3",
            four: "4",
            five: "5"
        },
        commentsModeration: {
            pending: "Pendente",
            approve: "Aprovado",
            reprove: "Reprovado"
        }
    },

    auxiliary: {
        lastSeparator: " e"
    },
    booleans: {
        yes: "Sim",
        no: "Não",
		true: "Sim",
		false: "Não"
    },
    races: {
        white: "Branco",
        black: "Preto"
    },
    date: {
        dateAndHourSeparator: 'às',
        dateToDate: 'até',
        today: "Hoje",
        yesterday: "Ontem",
		allDay: "Dia todo"
    },

    sw: {
        title: "Atualização",
        content: "Existe uma nova versão da aplicação, precisamos que você clique no botão abaixo para recarregar a página.",
        actions: {
            reload: {
                text: "Recarregar página"
            }
        }
    },
    recorderAudio: {
        rejectedPermission: "É preciso permitir acesso ao seu microfone",
        timedOut: "Limite de tempo esgotado, envie ou grave outro áudio"
    },
    snackBar: {
        messages: {
            success: "Sucesso",
            error: "Erro",
            internalServerError: "Ocorreu um erro de conexão com o servidor"
        },
        actions: {
            close: {
                text: "Fechar"
            },
            reload: {
                text: "Recarregar página"
            },
            restore: {
                text: "Desfazer"
            }
        }
    },
    errors: {
        moduleImport: "Existe uma nova versão da aplicação, precisamos que você clique no botão abaixo para recarregar a página.",
        connectionDown: "Você perdeu o acesso à rede",
		permissionError: "Você não possui permissão para acessar essa página.",
        connectionComeBack: "Sua conexão foi restabelecida",
        apiIsNotFound: "A conexão com o servidor foi perdida, entre em contato com o suporte",
		apiError: "Não consegui carregar algum elemento dessa página. Se isso atrapalhar o seu uso, entre em contato com suporte.",
    },

    dialog: {
        ariaLabel: "Caixa de diálogo",
        actions: {
            close: "Fechar",
            agree: "Concordo"
        }
    },
    localActions: {
        filters: {
            text: "Filtros",
            tooltip: "Filtros",
            badge: "Número de filtros ativos"
        },
		generateReport: {
			text: "Gerar relatório",
			tooltip: "Gerar relatório"
		}

    },
    confirmDialog: {
        title: "Atenção",
        body: "Deseja confirmar as alterações feitas?",
        action: "Sim",
        cancel: "Não"
    },

    listActions: {
        edit: {
            text: "Editar"
        },
        delete: {
            text: "Excluir"
        },
		close: {
			text: "Fechar"
		},
		save: {
			text: "Salvar"
		},
		duplicate: {
			text: "Duplicar"
		},
        remove: {
            text: "Remover"
        },
        recover: {
            text: "Restaurar"
        },
        inactive: {
            text: "Inativar"
        },
		view: {
			text: "Visualizar"
		},
		reprove: {
			text: "Reprovar"
		},
		approve: {
			text: "Aprovar"
		},
		viewChanges: {
			text: "Visualizar alterações"
		},
		finish: {
			text: "Finalizar"
		},
        active: {
            text: "Ativar"
        },
    },
    filters: {
        title: "Filtros",
        actions: {
            close: {
                text: "Fechar"
            },
            clear: {
                text: "Limpar"
            },
            save: {
                text: "Buscar"
            }
        },
        fields: {
            active: {
                label: "Situação",
                placeholder: "Escolha a situação",
                url: "situacao",
            }
        }
    },

    form: {
        updateSubTitle: "",
        createSubTitle: "",
        select: {
            lastSeparator: " e",
            nothing: "Nenhum",
            nothingFemale: "Nenhuma",
        },
        dateRange: {
            initPlaceholder: "Data inicial",
            finalPlaceholder: "Data final"
        },
        input: {
            password: {
                show: "Exibir senha",
                hide: "Esconder senha"
            }
        },
        editor: {
            link: {
                label: "Link",
                placeholder: "Informe o link",
                save: 'Salvar',
                edit: 'Editar',
                remove: 'Remover',
                visitUrl: 'Visitar link'
            }
        },
        chips: {
            remove: "Excluir item",
            notFound: "Itens não encontrados"
        },
        autocomplete: {
            notFound: "Itens não encontrados",
			clear: "Limpar"
		},
        errors: {
            minlength: "Mínimo de {{ minlength }} caracteres",
            maxlength: "Máximo de {{ maxlength }} caracteres",
            min: "Valor mínimo permitido é {{ min }}",
            max: "Valor máximo permitido é {{ max }}",
            email: "E-mail é inválido",
            passwordConfirmation: "As senhas devem ser iguais",
            required: "Campo é obrigatório",
            matDatepickerParse: "Data é inválida",
            matStartDateInvalid: "Data é inválida",
            matEndDateInvalid: "Data é inválida",
            matDatepickerFilter: "Data é inválida",
            dateRangeMessageSeparated: "Data é inválida",
            url: "URL é inválida",
            color: "Cor é inválida",
            cnpj: "CNPJ é inválido",
            cpfOrEmail: "E-mail ou CPF é inválido",
            cep: "CEP é inválido",
            cpf: "CPF é inválido",
            hourMinute: "Hora é inválida",
            phone: "Telefone é inválido",
            cnh: "CNH é inválida",
            licensePlate: "Placa é inválida",
            overSize: "O tamanho dos arquivos não pode ultrapassar 200 megabytes",
            invalidStartEndTime: "O horário final não deve ser menor que o horário inicial",
			notANumber: "Não é um número"
        },
        chooseIcon: {
            messages: {
                text: "Selecionado: {{ icon }}",
                notFound: "Nenhum adicionado",
            },
            buttons: {
                add: {
                    text: "Adicionar"
                },
                change: {
                    text: "Alterar"
                }
            },
            dialog: {
                title: "Escolha um ícone",
                noResults: "Ícones não encontrados",
                form: {
                    fields: {
                        search: {
                            label: "Buscar (em inglês)",
                            placeholder: "Informe o nome, categoria ou etiqueta do ícone"
                        }
                    }
                },
                buttons: {
                    showMore: {
                        text: "Mostrar mais"
                    }
                }
            }
        },
        actions: {
            clear: {
                text: "Limpar formulário"
            },
            cancel: {
                text: "Cancelar"
            },
            create: {
                text: "Criar"
            },
            save: {
                text: "Salvar"
            },
            close: {
                text: "Fechar"
            }
        }
    },

    notFound: {
        title: "Página não encontrada",
        description: `
                Você tentou acessar uma página que não existe. Clique no botão abaixo para voltar.
            `,
        actions: {
            back: {
                text: "Voltar"
            }
        }
    },
    noAccess: {
        title: "Sem permissão de acesso",
        description: `
                Você não possui permissão para acessar essa página. Clique no botão abaixo para voltar.
            `,
        actions: {
            back: {
                text: "Voltar"
            }
        }
    },

    table: {
        expanded: {
            column: "Coluna expansível",
            row: "Linha expansível"
        },
        noResults: {
            text: "Resultados não encontrados",
            description: "Tente cadastrar ou filtrar um item"
        },
        paginator: {
            firstPageLabel: "Primeira página",
            lastPageLabel: "Última página",
            itemsPerPageLabel: "Itens por página",
            nextPageLabel: "Próxima página",
            previousPageLabel: "Página anterior",
            rangeLabel: "{{ index }} até {{ limit }} de {{ length }}",
            perPageUrl: "por-pagina",
            pageUrl: "pagina"
        },
        columns: {
            actions: "",
            dragAction: {
                tooltip: "Arraste para mover esta linha"
            },
            selection: {
                all: {
                    select: "Selecionar todas as linhas",
                    deselect: "De-selecionar todas as linhas"
                },
                row: {
                    select: "Selecionar essa linha",
                    deselect: "De-selecionar essa linha"
                }
            }
        }
    },
    fileViewer:{
        actions: {
            download: "Download",
            view: "Visualizar",
            close: "Fechar",
        }
    },
    fileRenderer:{
        notFound: {
            label: "Arquivo indisponível",
            description: "Não foi possível encontrar este arquivo",
        }
    },
    filePicker:{
        actions: {
            addMedia: "Adicionar anexo",
            changeMedia: "Substituir anexos",
        },
        noResults: {
            label: "Nenhum anexo adicionado"
        }
    },
    hintBox:{
        labels: {
            expanded: "Ocultar dicas de uso",
            notExpanded: "Exibir dicas de uso",
        },
    },
    dynamicForm:{
        drawer: {
            title: "Adicionar novos campos",
            fields: {
                fieldName: {
                    label: "Nome do campo",
                    placeholder: "O nome que o colaborador verá ao se inscrever."
                },
                fieldType: {
                    label: "Tipo de campo",
                    placeholder: "Um texto? uma lista de opções?"
                },
                required: {
                    label: "É obrigatório?",
                    tooltip: "Caso você marque este campo o colaborador não poderá enviar o formulário sem preencher essa informação."
                },
                onlyForParticipant: {
                    label: "Exibir este campo somente para o participante?",
                    tooltip: "Caso você marque este campo somente o participante que o colaborador adicionar irá vê-lo."
                },
                maxLength: {
                    label: "Tamanho máximo dos caracteres",
                    placeholder: "Quantos números ou letras o colaborador pode inserir no máximo?"
                },
                maxNumber: {
                    label: "Valor máximo do número",
                    placeholder: "Quanto o colaborador pode inserir neste campo?"
                },
                minNumber: {
                    label: "Valor mínimo do número",
                    placeholder: "Quanto o colaborador pode inserir neste campo?"
                },
            },
            configurations: {
                options: {
                    title: "Opções da lista",
                    tooltip: "Aqui você pode informar as opções que serão exibidas nesta lista",
                    fields: {
                        name: {
                            label: "Nome da opção",
                            placeholder: "É assim que o colaborador verá esta opção"
                        }
                    }
                },
            },
            actions: {
                save: {
                    text: "Salvar"
                },
                addField: {
                    text: "Adicionar campo ao formulário"
                },
                editField: {
                    text: "Alterar campo no formulário"
                },
                cancelUpdate: {
                    text: "Cancelar edição"
                },
            },
            errors: {
                fieldNameExists: "Um campo com este nome já existe. Por favor, informe outro valor"
            }
        },
        noResults: {
            label: "Nenhum campo foi cadastrado no formulário",
            description: 'Clique em "+ Adicionar campo ao formulário" para adicioná-los'
        },
        fieldActions: {
            edit: {
                text: "Editar este campo"
            },
            move: {
                text: "Mover a posição deste campo"
            },
            delete: {
                text: "Excluir este campo do formulário"
            },
        }
    },
    confirmAction:{
        title: "Você tem certeza de que deseja executar esta ação?",
        body: "Esta ação não poderá ser revertida",
        cancel: "Cancelar",
        delete: "Sim, executar",
    },
    advancedEditor:{
        defaultActions: {
            font: {
                tooltip: "Fonte"
            },
            header: {
                tooltip: "Títulos"
            },
            size: {
                tooltip: "Definir tamanho da fonte",
                placeholder: "Tamanho"
            },
            bold: {
                tooltip: "Negrito"
            },
            italic: {
                tooltip: "Itálico"
            },
            underline: {
                tooltip: "Sublinhado"
            },
            strike: {
                tooltip: "Tachado"
            },
            align: {
                tooltip: "Alinhamentos"
            },
            list: {
                ordered:{
                    tooltip: "Adicionar lista ordenada"
                },
                bullet:{
                    tooltip: "Adicionar lista não ordenada"
                },
            },
            image: {
                tooltip: "Adicionar imagem"
            },
            link: {
                tooltip: "Inserir link"
            },
            background: {
                tooltip: "Selecionar cor de fundo"
            },
            color: {
                tooltip: "Selecionar cor do texto"
            },
            variables: {
                tooltip: "Selecionar variável",
                placeholder: "Variáveis"
            },
        },
        options:{
            digitalSignature: {
                value: '$ASSINATURA_DIGITAL',
                label: 'Assinatura digital',
            },

            candidateOffice: {
                value: '$CARGO',
                label: 'Cargo',
            },
            assetNumber: {
                value: '$NUMERO_DE_PATRIMONIO',
                label: 'Número de Patrimônio',
            },
            candidateCPF: {
                value: '$CPF_DO_COLABORADOR',
                label: 'CPF do colaborador',
            },

            generationDate: {
                value: '$DATA_NA_GERACAO',
                label: 'Data na geração',
            },

            candidateRegistration: {
                value: '$MATRICULA_DO_COLABORADOR',
                label: 'Matrícula do colaborador',
            },

            educationalInstitution: {
                value: '$NOME_DA_INSTITUICAO_DE_ENSINO',
                label: 'Nome da instituição de ensino',
            },
            candidateName: {
                value: '$NOME_DO_COLABORADOR',
                label: 'Nome do colaborador',
            },
            courseName: {
                value: '$NOME_DO_CURSO',
                label: 'Nome do curso',
            },

            refundPercentage: {
                value: '$PERCENTUAL_DO_REEMBOLSO',
                label: 'Percentual do reembolso',
            },

            typeOfGraduation: {
                value: '$TIPO_DE_FORMACAO',
                label: 'Tipo de formação',
            },
            candidateShift: {
                value: '$TURNO',
                label: 'Turno',
            },

            courseValue: {
                value: '$VALOR_DO_CURSO',
                label: 'Valor do curso',
            },
            refundValue: {
                value: '$VALOR_DO_REEMBOLSO',
                label: 'Valor do reembolso',
            },

            equipmentModel: {
                value: '$MODELO_DO_ATIVO',
                label: 'Modelo do Ativo',
            },
            equipmentType: {
                value: '$TIPO_DO_ATIVO',
                label: 'Tipo do ativo',
            },
            equipmentBrand: {
                value: '$MARCA_DO_ATIVO',
                label: 'Marca do ativo',
            },
            equipmentValue: {
                value: '$VALOR_DO_ATIVO',
                label: 'Valor do ativo',
            },
            operationalSystem: {
                value: '$SISTEMA_OPERACIONAL',
                label: 'Sistema operacional',
            },
            serialNumber: {
                value: '$NUMERO_DE_SERIE',
                label: 'Número de série',
            },
        }
    },
    list: {
        noResults: {
            text: "Resultados não encontrados",
            description: "Cadastre um item e ele aparecerá aqui",
        },
        moreResults: {
            text: "Mostrar mais"
        }
    },


	footer: {
		title: "Desenvolvido por"
	},
	developedBy: {
		title: "Wolvee"
	},
	accountOutOfConnection: {
		label: "Problemas na sua autenticação, entre em contato com suporte técnico! - ACT001"
	},
	globalLoading: {
		message: "",
		login: "Entrando no {{ projectName }}",
		loading: "Carregando o {{ projectName }}",
		logout: "Saindo do {{ projectName }}",
		switchUser: "Trocando de usuário",
		joinWithOtherUser: "Entrando com outro usuário",
		authentication: "Autenticando o usuário",
	},
	tabs: {
		urlName: "aba"
	},


	emoji: {
		search: 'Procurar emojis',
		emojilist: 'Lista de emojis',
		notfound: 'Nenhum emoji encontrado',
		clear: 'Limpar',
		categories: {
			search: 'Emojis encontrados',
			recent: 'Frequentemente usados',
			people: 'Faces e pessoas',
			nature: 'Animais e natureza',
			foods: 'Comidas e bebidas',
			activity: 'Atividades',
			places: 'Viagens e lugares',
			objects: 'Objetos',
			symbols: 'Símbolos',
			flags: 'Bandeiras',
			custom: 'Customizados'
		},
		skintones: {
			first: 'Tom de pele amarelo',
			second: 'Tom de pele claro',
			third: 'Tom de pele médio-claro',
			fourth: 'Tom de pele médio',
			fifth: 'Tom de pele médio-escuro',
			sixth: 'Tom de pele escuro'
		}
	},
	radioVote: {
		quantity: "Quantidade de votos"
	},
	basicCarousel: {
		actions: {
			next: {
				text: "Próxima imagem"
			},
			previous: {
				text: "Imagem anterior"
			}
		}
	},

	actions: {
		showMore: "Mostrar mais",
		seeAttachments: "Ver anexos",
		addObservation: "Observações",
		observationAndAttachments: "Anexos / observações",
		setAttachments: "Adicionar anexos"
	},

	notifications: {},
	unauthorizedPage: {
		redirect: {
			back: "Voltar"
		}
	},
	tooltipUser: {
		edit: "Editar",
		chat: "Conversar",
		congratulate: "Dê os parabéns",
		onVacation: "Em período de férias",
		myProfile: "Meu perfil",
		profile: "Ver perfil"
	},

	notificationsCenter: {
		title: "Central de notificações",
		read: "Notificação lida",
		unread: "Notificação não lida",
		link: "URL da notificação",
		noResults: {
			text: "Sem notificações atualmente"
		},
		actions: {
			close: {
				text: "Fechar"
			},
			notSeen: {
				text: "Exibir não visualizadas"
			},
			all: {
				text: "Exibir todas"
			},
			readAll: {
				text: "Marcar todas como lidas"
			}
		}
	},
};
