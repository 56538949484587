<div class="local-actions"
	 [ngClass]="{'local-actions--in-tabs': isTabs()}"
	 *ngIf="areThereLocalActions()">
	<app-form-button *ngFor="let action of localActions"
					 class="local-actions__btn"
					 [class]="(action?.condition ? action?.condition() : true) ? 'local-actions__btn--show' : 'local-actions__btn--hide'"
					 [click]="!action.href ? action?.click?.bind(this) : null"
					 [href]="action?.href"
					 [icon]="action?.icon"
					 [color]="action?.color"
					 [theme]="action?.theme"
					 [type]="action?.type"
					 [badge]="action?.badge"
					 [condition]="true"
					 [disabled]="action.disabled"
					 [tooltip]="tooltip(action) | translate"
					 [text]="action?.text">
	</app-form-button>
</div>
