import * as fromRouter from '@ngrx/router-store';
import {routerReducer} from '@ngrx/router-store';
import {ActionReducer, ActionReducerMap, combineReducers} from '@ngrx/store';

import * as menusReducer from './menus';

export interface State{
    routerState: fromRouter.RouterReducerState<any>;
	menuState: menusReducer.State;

}

const reducers: ActionReducerMap<State> = {
	menuState: menusReducer.reducer,
    routerState: routerReducer

};


export const productionReducer: ActionReducer<State> = combineReducers(reducers);
