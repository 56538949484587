import { Component } from '@angular/core';
import { Router } from '@angular/router';

import { Store } from "@ngrx/store";

import { SignInService } from "../../../modules/sign-in/sign-in.service";
import { MenuService } from "../menu.service";
import { MenuToolbarService } from "./menu-toolbar.service";

import { CallbackHelpers } from "../../../shared/helpers/callback-helpers";
import { environment } from "../../../../environments/environment";
import { GlobalLoadingService } from "../../../shared/components/default/global-loading/global-loading.service";
import { toolbarSelectors } from "../../../shared/store/selectors";
import { ToolbarAction } from "../../../shared/models/toolbar-action";
import { Authentication } from "../../authentication/authentication";
import { Translate } from "../../../shared/helpers/translate";

@Component({
	selector: 'app-menu-toolbar',
	templateUrl: './menu-toolbar.component.html',
	styleUrls: ['./menu-toolbar.component.scss'],
	preserveWhitespaces: false
})
export class MenuToolbarComponent {
	env = environment;
	isOpen = true;
	projectName = 'JoinIn';
	actions$ = this.store.select(toolbarSelectors.selectToolbarActions(this));

	user = Authentication.getUser();

	constructor(
		public signInService: SignInService,
		public menuService: MenuService,
		public router: Router,
		public toolbarServ: MenuToolbarService,
		public globalLoadingService: GlobalLoadingService,
		// public notificationsCenterServ: NotificationsCenterService,
		public store: Store,
		// public notificationsServ: NotificationsService
	) {
		this.toolbarServ.isOpenSubject.subscribe(state => this.isOpen = state);
	}

	goTo(url: string) {
		this.router.navigateByUrl(url)
	}

	onActionClick(action: ToolbarAction) {
		action?.click?.();
	}

	executeBadge(badge: any) {
		const value = badge?.value?.();
		return {...badge, value};
	}

	getTooltip(action: ToolbarAction){
		const tooltip = CallbackHelpers.toCallback(action.tooltip);

		return tooltip();
	}

	urlProfile() {
		return  Translate.value('routes.myProfile.path');
	}
}
