import { AfterViewInit, ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Params, Router } from '@angular/router';
import { MatDrawerMode, MatSidenav } from '@angular/material/sidenav';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';

import { Observable, Subscription } from 'rxjs';
import { filter } from "rxjs/operators";
import { FiltersService } from './filters.service';
import { MatDatepicker } from "@angular/material/datepicker";
import { Store } from "@ngrx/store";
import { FormService } from "../../shared/components/default/form/form.service";
import { Generic } from "../../shared/models/generic";
import { FilterField } from "./interfaces/filter-field";
import { FormButton } from "../../shared/models/form-button";
import { TabsService } from "../../shared/components/custom/tabs/tabs.service";
import { Moment } from "../../shared/helpers/moment";
import { Auxiliary } from "../../shared/helpers/auxiliary";
import { ObjectHelpers } from "../../shared/helpers/object";
import { Translate } from "../../shared/helpers/translate";
import { FilterUrlTypesEnum } from "./enums/filter-url-types.enum";
import { FilterClearTypesEnum } from "./enums/filter-clear-types.enum";
import { ConvertHelpers } from "../../shared/helpers/convert-helpers";
import { Filter } from "./interfaces/filter";
import { TablePaginatorService } from "../../shared/components/custom/table/paginator/table-paginator.service";

@Component({
	selector: 'app-filters',
	templateUrl: './filters.component.html',
	styleUrls: ['./filters.component.scss']
})
export class FiltersComponent implements OnInit, Filter, OnDestroy, AfterViewInit {
	@ViewChild('filters') filters: MatSidenav = {} as MatSidenav;

	formsHelper = FormService;
	component: any;
	form: FormGroup = this.formBuilder.group({});
	defaultValues: Generic = {};
	fields: FilterField[] = [];
	mode: MatDrawerMode = 'over';
	isSearching = false;
	searchDebounce = 500;

	save: FormButton = {
		disabled: () => this.form.invalid,
		color: "primary",
		theme: "raised",
		type: "submit",
		text: "filters.actions.save.text",
		condition: () => true,
		click: () => this.search.bind(this)
	};

	clear: FormButton = {
		color: "accent",
		theme: "stroked",
		condition: () => true,
		click: this.clearAction.bind(this),
		type: "button",
		action: FilterClearTypesEnum.CLEAR_ALL,
		text: 'filters.actions.clear.text'
	};

	private subscriptions: Subscription[] = [];
	private canUseLocalStorageParams = true;

	constructor(
		private formBuilder: FormBuilder,
		private filtersService: FiltersService,
		private store: Store,
		private router: Router,
		private activatedRoute: ActivatedRoute,
		private changeDetector: ChangeDetectorRef,
		private tabsService: TabsService,
		private tablePaginatorService: TablePaginatorService
	) {
	}

	ngOnInit(): void {
		this.setForm(this.formBuilder.group({}));
		this.setFields([]);

		this.subscriptions.push(
			this.filtersService
				.onToggle()
				.subscribe(() => this.filters?.toggle()),
			this.filtersService
				.onClose()
				.subscribe(() => this.close()),
			this.filtersService
				.watchFilters()
				.subscribe(filters => {
					this.setComponent(filters?.component);
					this.setForm(filters?.form);
					this.setFields(filters?.fields);
					this.setClear(filters?.clear);
					this.setFieldValueByURL();
					this.sendActivesFilters();
				}),
			this.filtersService.onSearch$.subscribe(() => this.search(true)),
			this.filtersService.onClearAll$.subscribe(() => this.clearAll()),
			this.router
				.events
				.pipe(filter(event => event instanceof NavigationEnd))
				.subscribe(() => this.close()),
			this.activatedRoute.queryParams.subscribe(() => this.sendActivesFilters())
		);
	}

	ngAfterViewInit() {
		this.subscriptions.push(
			this.filters.openedStart.subscribe(() => {
				// this.store.dispatch(menuActions.setDisplayState({payload: "closed"}));
			}),
			this.filters.closedStart.subscribe(() => {
				// if (!this.isAtNotebook())
				// 	this.store.dispatch(menuActions.setDisplayState({payload: "open"}));
			}),
		);
	}

	// private isAtNotebook() {
	// 	return window.innerWidth <= NOTEBOOK_BREAKPOINT;
	// }

	search(callGetOnComponent: boolean = true): void {
		setTimeout(() => {
			this.isSearching = false;
		}, this.searchDebounce);

		if (this.isSearching) return;

		this.isSearching = true;

		this.canUseLocalStorageParams = false;
		const parameters = this.removeNullParameters(this.formsHelper.createParametersByForm(this.form as FormGroup));
		const converted = this.removeNullParameters(this.convertParametersToCommaSeparated(parameters));

		this.putParametersToURL(parameters);
		if (callGetOnComponent) this.sendParametersToComponent(converted);

		this.close();
	}

	clearAll() {
		this.form.reset();
		this.filtersService.search();
	}

	onLoadOptions(options: {
		options: any[];
		filteredOptions: Observable<Generic[]>;
	}, currentFilter: FilterField) {
		const control = this.form?.get(currentFilter.name ?? '');

		currentFilter?.onLoadOptions?.(
			options?.options ?? [],
			control as FormControl
		);
	}

	onMonthSelect({datepicker, normalizedMonth}: {
		normalizedMonth: Moment;
		datepicker: MatDatepicker<any>;
		date: FormControl;
	}, field: FilterField) {
		if (!field.closeOnMonthSelect) return;
		const ctrlValue = Moment.moment();

		//@ts-ignore
		ctrlValue.month(normalizedMonth.month());
		//@ts-ignore
		ctrlValue.year(normalizedMonth.year());

		this.form.get(field.name ?? '')?.setValue(ctrlValue.toDate(), {emitEvent: false});

		datepicker.close();
	}

	close(): void {
		this.filters?.close?.();
		this.tabsService.sendUpdatePagination(true);
	}

	isModelDateRange(field: FilterField): boolean {
		return field.model === 'date-range';
	};

	ngOnDestroy(): void {
		Auxiliary.unsubscribeAll(this.subscriptions);
	}

	private convertParametersToCommaSeparated(parameters: Generic) {
		return ObjectHelpers.map(parameters, (key, value) => {
			const filterModel = this.fields.find(field =>
				field.name === key
			);

			if (filterModel?.model === "select" && filterModel?.multiple && Array.isArray(value)) {
				return {
					key,
					value: ConvertHelpers.arrayToString(value)
				};
			}

			if (filterModel?.model === "chips" && Array.isArray(value)) {
				return {
					key,
					value: ConvertHelpers.arrayToString(value)
				};
			}

			return {key, value};
		});
	}

	private manipulateFieldToURL(parameters: Params, name: string = '', urlParameter: string = ''): void {
		let fieldValue = parameters[name];

		const filterModel = this.fields.find(field =>
			field.name === name
		);

		if (filterModel?.model === "select" && filterModel?.multiple && Array.isArray(fieldValue)) {
			fieldValue = ConvertHelpers.arrayToString(fieldValue);
		}

		if (filterModel?.model === "chips" && Array.isArray(fieldValue)) {
			fieldValue = ConvertHelpers.arrayToString(fieldValue);
		}

		delete parameters[name];

		if (
			!Auxiliary.isUndefined(fieldValue) &&
			!Auxiliary.isNull(fieldValue) &&
			!Auxiliary.isEmptyString(fieldValue)
		) parameters[urlParameter] = Auxiliary.removeDoubleQuotationMarks(JSON.stringify(fieldValue));
	}

	private setClear(clear: FormButton | undefined): void {
		if (clear) this.clear = Auxiliary.assignAllObjects(this.clear, clear) as FormButton;
	}

	private setForm(form: FormGroup): void {
		if (form) {
			this.form = form;
			this.defaultValues = Auxiliary.copy(form.getRawValue());
		}
	}

	private setFields(fields: FilterField[]): void {
		if (fields?.length) {
			this.fields = fields.map(field => {
				const defaultField: Partial<FilterField> = {
					leadZeroDateTime: false,
					dropSpecialCharacters: true,
				};

				if (field.urlParameter) field.urlParameter = Translate.value(field.urlParameter);
				if (field.startUrlParameter) field.startUrlParameter = Translate.value(field.startUrlParameter);
				if (field.endUrlParameter) field.endUrlParameter = Translate.value(field.endUrlParameter);

				return {
					...defaultField,
					...field
				};
			});
		} else this.fields = [];
	}

	private setComponent(component: any): void {
		if (component) {
			this.canUseLocalStorageParams = true;
			this.component = component;
		}
	}

	private getActivesFiltersByParameters(parameters: Params = {}): number {
		const parametersCopy = Auxiliary.copy(parameters);

		for (const key in parametersCopy) {
			if (parametersCopy.hasOwnProperty(key)) {
				const keys = [
					this.isDateRangeAllFilled(parametersCopy, this.fields || [])
					// this.formsHelper.hasNotDefaultFieldChanged(parametersCopy, key, this.defaultValues, this.form)
				];

				keys.forEach(newKey => delete parametersCopy[Auxiliary.asKeyof(newKey, parametersCopy)]);
			}
		}

		const values = Object.values(parametersCopy);

		return values.filter(value => {
			if(Array.isArray(value)) return !!value?.length;
			const isNullable = typeof value === undefined || typeof value === null;

			return !isNullable;
		}).length;
	}

	private clearAction(): void {
		switch (this.clear?.action) {
			case FilterClearTypesEnum.DEFAULT_FILTERS:
				this.form?.patchValue(this.defaultValues);
				break;
			case FilterClearTypesEnum.CLEAR_ALL:
				if (this.form) this.clearForm();
				break;
		}
	}

	private clearForm() {
		this.formsHelper.clearForm(this.form);

		const chipFields = this.fields.filter(filterToUse => filterToUse.model === "chips")
			.map(filterToUse => filterToUse.name)
			.filter((fieldName): fieldName is string => !!fieldName);

		chipFields.forEach(fieldName => {
			this.form.get(fieldName)?.reset([]);
		});
	}

	private chooseMethodToManipulate(action: FilterUrlTypesEnum): any {
		switch (action) {
			case FilterUrlTypesEnum.MANIPULATE_TO_URL:
				return this.manipulateFieldToURL.bind(this);
			case FilterUrlTypesEnum.MANIPULATE_ON_URL:
				return this.manipulateFieldOnURL.bind(this);
		}
	}

	private eachFields(action: FilterUrlTypesEnum, parameters: Params): void {
		this.fields?.forEach(field => {
			const method = this.chooseMethodToManipulate(action);

			if (this.isModelDateRange(field)) {
				method(parameters, field.startName, field.startUrlParameter);
				method(parameters, field.endName, field.endUrlParameter);
			} else method(parameters, field.name, field.urlParameter);
		});
	}

	private setFieldValueByURL(): void {
		this.subscriptions.push(
			this.activatedRoute
				.queryParams
				.subscribe(parameters => {
					this.eachFields(
						FilterUrlTypesEnum.MANIPULATE_ON_URL,
						{
							...(parameters || {}),
							...((this.canUseLocalStorageParams && this.component?.localStorageParams) || {})
						}
					);
				})
		);

		if(this.fields.length === 0) return;

		this.search(false);
	}

	private manipulateFieldOnURL(parameters: Params, name: string = '', urlParameter: string = ''): void {
		let valueByURL = Auxiliary.setCorrectType(parameters[urlParameter]);
		const objectWithValue: Generic = {};
		const fieldValue = this.form?.get(name)?.value;
		const filterModel = this.fields.find(field =>
			field.name === name
		);

		if (
			filterModel?.model === "select" &&
			filterModel?.multiple &&
			(typeof valueByURL === "string" || typeof valueByURL === "number")
		) {
			valueByURL = valueByURL.toString().split(",").map(option => +option);
		}

		if (filterModel?.model === "chips" && (typeof valueByURL === "string" || typeof valueByURL === "number")) {
			valueByURL = valueByURL.toString().split(",").map(option => +option);
		}

		const value = !Auxiliary.isNull(valueByURL) && !Auxiliary.isUndefined(valueByURL) ? valueByURL : fieldValue;

		objectWithValue[name] = value;

		if (!Auxiliary.isNull(value) && !Auxiliary.isUndefined(value)) this.form?.patchValue(objectWithValue);

		this.changeDetector.detectChanges();
	}

	private putParametersToURL(parameters: Params): void {
		const newParameters = {...parameters};
		console.log(this.router.url)
		this.eachFields(FilterUrlTypesEnum.MANIPULATE_TO_URL, newParameters);

		setTimeout(() => {
			this.tablePaginatorService.setParameters({...this.tablePaginatorService.getParameters(), page: 1});
			this.router.navigate([],
				{
					queryParams: Object.assign(
						newParameters,
						this.tabsService.getParametersOnURL(),
						this.tablePaginatorService.getTranslatedParameters()
					),
					relativeTo: this.activatedRoute
				}
			);
		});
	}

	private isDateRangeAllFilled(
		parametersObject: Params,
		fields: FilterField[],
		propertiesToEvaluate = ['startName', 'endName'],
		sendProperty = 'endName'
	): string {
		const dateRangeField = fields.filter(field => this.isModelDateRange(field))[0] as Generic;
		const areThereBothValues = propertiesToEvaluate.every(
			propertyName =>
				dateRangeField
				&&
				parametersObject.hasOwnProperty(dateRangeField[propertyName])
				&&
				parametersObject[Auxiliary.asKeyof(dateRangeField[propertyName], parametersObject)]
		);

		return areThereBothValues ? dateRangeField[sendProperty] : undefined;
	};

	private sendActivesFilters(): void {
		setTimeout(() =>
				this.filtersService.sendActives(
					this.getActivesFiltersByParameters(
						this.formsHelper.createParametersByForm(this.form as FormGroup)
					)
				)
			, 50);
	}

	private sendParametersToComponent(parameters: Params): void {
		this.component.get(parameters);
	}

	private filterNotValid(params: Generic, item: string){
		return !params[item] || params[item] === '0' || params[item].length === 0
	}
	private removeNullParameters(params: Generic) {

		Object.keys(params).forEach(item => {
			if(this.filterNotValid(params, item)){
				delete params[item]
			}
		})

		return params
	}
}
