import { Menu } from "../../models/menu";
import { ArraysHelper } from "../arrays-helpers";
import { Translate } from "../translate";

export class MenuUtils {
    static sortGroup(groups: { name: string; list: Menu[] }[], exceptions: { groupName: string; customSort: (a: string, b: string) => number }[] = []) {
        const sortedByGroups = groups.sort((a, b) => {
            const exception = exceptions.find(exce =>
                exce.groupName === a.name || exce.groupName === b.name);

            if (!!exception) return exception.customSort(a.name, b.name);

            return a.name.localeCompare(b.name);
        });

        return sortedByGroups.map(group => ({
            ...group,
            list: group.list.sort((a, b) => a.name.localeCompare(b.name))
        }));
    };

    static groupMenus(menus: Menu[]) {
        const grouped = ArraysHelper.groupBy(menus, menu => menu.group);

        const subspaceGroupName = Translate.value('menu.group.subspaces');
        const noGroupName = '';

        return [...this.sortGroup(grouped, [
            {
                groupName: subspaceGroupName,
                customSort: (a, b) =>
                    a === subspaceGroupName ? -1 : b === subspaceGroupName ? 1 : 0
            },
            {
                groupName: noGroupName,
                customSort: (a, b) =>
                    a === noGroupName ? -1 : b === noGroupName ? 1 : 0
            },
        ])];
    };
}

