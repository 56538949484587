import { ROUTES_TRANSLATIONS } from "./routes";
import { GENERIC_TRANSLATIONS } from "./generic";
import { SIGN_IN_TRANSLATE } from "./sign-in-translate";
import { MENU_TRANSLATE } from "./menu-translate";
import { APPOINTMENTS_TRANSLATE } from "./appointments-translate";
import { CHARGES_TRANSLATE } from "./charges-translate";
import { HOME_TRANSLATE } from "./home-translate";
import { MY_PROFILE_TRANSLATE } from "./my-profle-translate";
import {PASSWORD_RESET_TRANSLATE} from "./password-reset-translate";
import {PASSWORD_EDIT_TRANSLATE} from "./password-edit-translate";


export const TRANSLATIONS: any = {
    /* eslint-disable @typescript-eslint/naming-convention */
    "pt-BR": {
        ...ROUTES_TRANSLATIONS,
        ...GENERIC_TRANSLATIONS,
        ...MENU_TRANSLATE,
        ...SIGN_IN_TRANSLATE,
        ...HOME_TRANSLATE,
        ...APPOINTMENTS_TRANSLATE,
        ...CHARGES_TRANSLATE,
        ...MY_PROFILE_TRANSLATE,
        ...PASSWORD_RESET_TRANSLATE,
        ...PASSWORD_EDIT_TRANSLATE
    }
}
