<ng-container *appVar="fields$ | async as fields">
	<ng-container *appVar="form$ | async as form">
		<form
			*ngIf="!!form"
			autocomplete="off" class="filters__form" [formGroup]="form">
			<div class="filters__body" [style.justify-content]="position$ | async" *ngIf="!!fields.length && (canShow$ | async)">
				<div *ngFor="let field of fields">
					<app-form-input [label]="field.label"
									*ngIf="field.model === 'input'"
									[type]="field.type"
									[placeholder]="field.placeholder"
									[mask]="field.mask"
									[form]="form"
									[uppercase]="field.uppercase || false"
									[icon]="field.icon"
									[dropSpecialCharacters]="field.dropSpecialCharacters"
									[leadZeroDateTime]="field.leadZeroDateTime"
									[hint]="field.hint"
									[maxlength]="field.maxlength"
									[name]="field.name">
					</app-form-input>
					<app-form-datepicker [label]="field.label"
										 [name]="field.name"
										 [form]="form"
										 [startView]="field.startView || 'month'"
										 [formatDate]="field.formatDate"
										 (onMonthSelect)="onMonthSelect($event, field)"
										 [filter]="field.filter"
										 *ngIf="field.model === 'datepicker'"
										 [placeholder]="field.placeholder">
					</app-form-datepicker>
					<app-form-date-range [startName]="field.startName"
										 [endName]="field.endName"
										 [form]="form"
										 (onMonthSelect)="onMonthSelect($event, field)"
										 [formatDate]="field.formatDate"
										 [filter]="field.filter"
										 *ngIf="field.model === 'date-range'"
										 [label]="field.label">
					</app-form-date-range>
					<app-form-autocomplete [label]="field.label"
										   [service]="field.service"
										   [options]="field.options"
										   [sendProperty]="field.sendProperty"
										   [nameProperty]="field.nameProperty"
										   *ngIf="field.model === 'autocomplete'"
										   [placeholder]="field.placeholder"
										   [form]="form"
										   [perPage]="field.perPage"
										   [returnName]="field.returnName"
										   [sortProperty]="field.sortProperty"
										   [sortDirection]="field.sortDirection"
										   [paramsSubject]="field.paramsSubject"
										   [loadBefore]="field.loadBefore"
										   [forceGet]="field.forceGet"
										   (externalOptions)="onLoadOptions($event, field)"
										   [method]="field.method"
										   [name]="field.name">
					</app-form-autocomplete>
					<app-form-select [label]="field.label"
									 [nothing]="field.nothing"
									 [sendProperty]="field.sendProperty"
									 [nameProperty]="field.nameProperty"
									 [returnName]="field.returnName"
									 *ngIf="field.model === 'select'"
									 [perPage]="field.perPage"
									 [service]="field.service"
									 [options]="field.options"
									 [multiple]="field.multiple"
									 [method]="field.method"
									 [placeholder]="field.placeholder"
									 [form]="form"
									 [name]="field.name">
					</app-form-select>

					<div class="button-toggle" *ngIf="field.model === 'button-toggle'">
						<mat-button-toggle-group
							[formControl]="form | formControl: field.name">
							<mat-button-toggle *ngFor="let option of field.options"
											   [value]="option.id">{{option.name | translate}}</mat-button-toggle>
						</mat-button-toggle-group>
					</div>
				</div>
				<div
					*ngIf="canShowClear$ | async"
					class="clear">
					<app-form-button
						[tooltip]="clear.tooltip"
						[icon]="clear.icon"
						[color]="clear.color"
						[theme]="clear.theme"
						[text]="clear.text"
						(click)="clearFilters()"
					>

					</app-form-button>
				</div>
			</div>
		</form>
	</ng-container>
</ng-container>

