export const ROUTES_TRANSLATIONS = {

    routes: {
        breadcrumbs: {
            new: "Adicionar",
            update: "Editar",
            visualization: "Visualizar",
        },
        metas: {
            new: "",
            update: "",
            default: "",
            visualization: "",
            single: ":id",
        },

        genericRoutes: {
            new: 'new',
            empty: '',
            single: ':id'
        },
        allRoutePrefix: {
            configurations: 'configurations',
        },


        configurations: {
            path: '/configurations',
            default: '',
            data: {
                breadcrumbName: "Configurações",
                metas: {}
            }
        },

        // ROUTES CORE
        myProfile: {
            path: "/my-profile",
            default: "",
            data: {
                breadcrumbName: "Meu perfil",
                metas: {}
            }
        },
        passwordReset: {
            path: "/forgot-my-password",
            default: "",
            data: {
                breadcrumbName: "Esqueci minha senha",
                metas: {}
            }
        },
        passwordEdit: {
            path: "/change-password",
            default: "",
            data: {
                breadcrumbName: "Alterar senha",
                metas: {}
            }
        },
        login: {
            path: "/login",
            empty: "",
            nextPath: "proximo-caminho",
            default: "",
            data: {
                breadcrumbName: "Entrar",
                metas: {}
            }
        },

        home: {
            path: "/home",
            empty: "",
            nextPath: "proximo-caminho",
            default: "",
            data: {
                breadcrumbName: "Início",
                metas: {}
            }
        },
        notFound: {
            path: "/not-found",
            anything: "**",
            default: "",
            data: {
                breadcrumbName: "Página não encontrada",
                metas: {}
            }
        },
        noAccess: {
            path: "/no-access-permission",
            default: "",
            data: {
                breadcrumbName: "Sem permissão de acesso",
                metas: {}
            }
        },

        appointments:{
            path: "/appointments",
            data: {
                breadcrumbName: "Consultas",
                metas: {}
            }
        },
        charges:{
            path: "/charges",
            data: {
                breadcrumbName: "Cobranças",
                metas: {}
            }
        }
    },
};
