import { createSelector } from "@ngrx/store";

import { selectApp } from './app';
import { Menu } from "../../models/menu";
import { MenuUtils } from "../../helpers/utils/menu-utils";

export const selectMenuState = createSelector(
    selectApp,
    (state) => state.menuState
);


export const selectMenuItems = createSelector(
    (): Menu[] => {

        return [
            {
                name: "menu.items.appointments.name",
                icon: "medical_information",
                group: "",
                path: "routes.appointments.path"
            },
            {
                name: "menu.items.charges.name",
                icon: "description",
                group: "",
                path: "routes.charges.path"
            },
        ];
    },
);
export const selectMenusGrouped = createSelector(
    selectMenuItems,
    (menus) => {
        return MenuUtils.groupMenus(menus);
    }
);
export const selectDisplayState = createSelector(
    selectMenuState,
    state => {
        return state.displayState
    }
);


export const selectShouldBeOpen = createSelector(
    selectDisplayState,
    (state) => state === 'open'
);

export const selectRouteIsDefaultClosed = createSelector(
    () => true
);

export const selectCanISeeMenu = createSelector(
    selectMenuItems,
    (
    ) => {
        return true;
    }
);


export const selectMenuLoading = createSelector(
    selectMenuState,
    (state) => state.loading
);
