import {Injectable} from '@angular/core';
import {HttpResponse} from "@angular/common/http";
import { ActivatedRoute, NavigationEnd, Router } from "@angular/router";

import {TranslateService} from "@ngx-translate/core";
import { Translate } from "./shared/helpers/translate";
import { Authentication } from "./core/authentication/authentication";
import { GlobalLoadingService } from "./shared/components/default/global-loading/global-loading.service";
import { filter } from "rxjs/operators";

@Injectable({
	providedIn: 'root'
})
export class AppService {
	color = '';
	backgroundColor = '';


	constructor(
		private translateService: TranslateService,
		private route: ActivatedRoute,
		private router: Router,
		private globalLoadingService: GlobalLoadingService,
	) {
		this.watchChangeRoutes();
	}

	get canISeeBreadcrumb(){
		return true;
	}

	get initialUrl(): string {
		const nextPath = this.route.snapshot.queryParams[Translate.value('routes.login.nextPath')];
		const path = Translate.value("routes.appointments.path");
		return nextPath ? nextPath : path;
	}

	initApplicationWithUser(response: HttpResponse<any>, ms = 250) {
		setTimeout(() => {
			Authentication.setHeaders(Authentication.createHeadersByResponse(response));
			Authentication.setUser(response.body.user);
			this.router.navigateByUrl(this.initialUrl);


			setTimeout(() => {
				this.globalLoadingService.send({show: false})
			}, 1000);
		}, ms);
	}

	dataContentPx(propSize: string, removeProps: string[]): string {
		try {
			const content = document.querySelector('[data-content]') as HTMLElement;
			const computedStyle = getComputedStyle(content);
			const paddingTopAndLeft = removeProps.reduce((acc, cur) =>
					// @ts-ignore
					acc + Number(computedStyle[cur].replace(/\D/g, ''))
				, 0);

			// @ts-ignore
			return `${content[propSize] - paddingTopAndLeft}px`;
		} catch (e) {
			return '0px';
		}
	}

	watchChangeRoutes(): void {
		this.router
			.events
			.pipe(filter(evt => evt instanceof NavigationEnd))
			.subscribe(() => {
					// if(!Authentication.isLogged) this.goToLogin();
			});
	}

	// private getTranslatedUrl(translation: string): string {
	// 	let url = '';
	//
	// 	this.translateService.get(translation).subscribe(path => url = path.replace('/', ''));
	//
	// 	return url;
	// }


	// private goToLogin(): void {
	// 	const urlLogin = this.getTranslatedUrl('routes.login.path');
	// 	const nextPath = this.getTranslatedUrl('routes.login.nextPath');
	// 	const pathname = window.location.pathname?.trim();
	// 	const nextUrl = pathname && pathname !== "/" ? pathname : null;
	// 	const params = {queryParams: {}};
	//
	// 	this.router.navigate([urlLogin], nextUrl ? params : {});
	// }
}
