export const SIGN_IN_TRANSLATE = {
    signIn: {
        form: {
            title: "Entrar",
            fields: {
                login: {
                    label: "Usuário",
                    placeholder: "Informe o usuário"
                },
                password: {
                    label: "Senha",
                    placeholder: "Informe a senha"
                }
            },
            actions: {
                save: {
                    text: "Entrar"
                }
            }
        }
    },
}