import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';

import { TranslateModule } from "@ngx-translate/core";

import { FiltersComponent } from './filters.component';
import { CanShowPipe } from './pipes/can-show.pipe';
import { AngularMaterialModule } from "../../angular-material/angular-material.module";
import { FormModule } from "../../shared/components/default/form/form.module";
import { MenuBreadcrumbComponent } from "../menu/breadcrumb/menu-breadcrumb.component";
import { QuickFiltersComponent } from "./quick-filters/quick-filters.component";
import { DirectivesModule } from "../../shared/directives/directives.module";
import { FormPipeModule } from "../../shared/pipes/form/form-pipe.module";
import { RouterLinkWithHref } from "@angular/router";
import { LocalActionsModule } from "../local-actions/local-actions.module";

@NgModule({
    declarations: [
        FiltersComponent,
        CanShowPipe,
        MenuBreadcrumbComponent,
        QuickFiltersComponent
    ],
    imports: [
        CommonModule,
        AngularMaterialModule,
        FormModule,
        ReactiveFormsModule,
        TranslateModule,
        DirectivesModule,
        FormPipeModule,
        RouterLinkWithHref,
        LocalActionsModule
    ],
    exports: [
        FiltersComponent
    ]
})
export class FiltersModule{}
