import { Pipe, PipeTransform } from '@angular/core';
import { Observable, of } from "rxjs";
import { FilterField } from "../interfaces/filter-field";

@Pipe({
	name: 'canShow',
})
export class CanShowPipe implements PipeTransform {
	transform(field: FilterField): Observable<boolean> {
		if(!!field.showOnQuickFilters) return of(true);

	  	if(!field.hidden$) return of(false);
		return field.hidden$;
	}
}
