<div class="menu">
    <div class="menu__top">
		<app-menu-toolbar #toolbar class="menu__toolbar"></app-menu-toolbar>
	</div>
    <mat-sidenav-container
		(backdropClick)="close()"
		class="menu__side-container" [style.top]="top">
        <mat-sidenav #menu
					 *ngIf="canISeeBreadcrumbOrMenu"
					 (keydown.escape)="close()"
                     [mode]="mode"
					 [opened]="openedState$ | async"
                     class="menu__bar"
                     tabindex="-1">
            <aside class="mdc-drawer menu__container">
                <div class="mdc-drawer__content" *appVar="menuList$ | async as menus">
					<ng-container>
						<div *ngIf="!!menus.length">
							<nav class="mdc-list menu__list" *ngFor="let items of menus">
								<span class="menu__title">{{ items.name | translate }}</span>
								<a mat-list-item
								   *ngFor="let item of items.list"
								   matRipple
								   class="mdc-list-item menu__option"
								   [queryParamsHandling]="menuServ.chooseHandling(item.path | translate) | async"
								   [routerLink]="item.path | translate"
								   routerLinkActive="mdc-list-item--activated menu__option--selected"
								   (click)="clickItem()">
									<div class="menu__item">
										<span class="mdc-list-item__ripple"></span>
										<mat-icon [ngStyle]="{'color': item.color}" class="mdc-list-item__graphic menu__icon" aria-hidden="true">{{ item.icon }}</mat-icon>
										<p [ngStyle]="{'color': item.color}" class="mdc-list-item__text menu__item-name">{{ item.name | translate }}</p>
									</div>
								</a>
							</nav>
						</div>

						<app-no-results label="menu.notFound.text"
										class="menu__not-found"
										*ngIf="!menus.length"
										icon="category">
						</app-no-results>
					</ng-container>

					</div>

<!--                <div [ngStyle]="{'background-image': menuBg }" class="menu__bg" *ngIf="logoComp?.src">-->
				<!--                    <img [src]="getUrl()" [alt]="logoComp.alt | translate" class="menu__logo">-->
<!--                </div>-->

                <app-copyright class="menu__copyright" link="https://joinin.com.br" [version]="version"></app-copyright>
            </aside>
        </mat-sidenav>

        <mat-sidenav-content class="menu__content">
            <app-filters>
                <main data-content class="menu__components-area" [ngClass]="{
                'menu__components-area--create-action': !!createAction.text
                }">
                    <router-outlet></router-outlet>
                </main>
            </app-filters>
        </mat-sidenav-content>
    </mat-sidenav-container>
</div>
