import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { TranslateService } from "@ngx-translate/core";

import { TRANSLATIONS } from "./languages/pt-BR";
import { RoutesGuard } from "./core/guards/routes/routes.guard";
import { Translate } from "./shared/helpers/translate";


Translate.setInitialTranslations(TRANSLATIONS);

const LOGIN_DATA = {
  breadcrumbName: "routes.login.data.breadcrumbName",
  metas: "routes.login.data.metas",
};

const MY_PROFILE_DATA = {
  breadcrumbName: `routes.myProfile.data.breadcrumbName`,
  metas: `routes.myProfile.data.metas`,
};

const APPOINTMETS_DATA = {
  breadcrumbName: `routes.appointments.data.breadcrumbName`,
  metas: `routes.appointments.data.metas`,
};

const CHARGES_DATA = {
  breadcrumbName: `routes.charges.data.breadcrumbName`,
  metas: `routes.charges.data.metas`,
};

const NOT_FOUND_DATA = {
  breadcrumbName: "routes.notFound.data.breadcrumbName",
  metas: "routes.notFound.data.metas",
};

const ROUTES: Routes = [
  {
    path: "routes.passwordReset.path",
    loadChildren: () =>
      import("./modules/password-reset/password-reset.module").then(
        (module) => module.PasswordResetModule
      ),
  },

  {
    path: "routes.myProfile.path",
    loadChildren: () =>
        import("./modules/my-profile/my-profile.module").then(
            (module) => module.MyProfileModule
        ),
    canActivate: [RoutesGuard],
    data: MY_PROFILE_DATA,
  },

  {
    path: "routes.appointments.path",
    loadChildren: () =>
        import("./modules/appointments/appointments.module").then(
            (module) => module.AppointmentsModule
        ),
    canActivate: [RoutesGuard],
    data: APPOINTMETS_DATA,
  },

  {
    path: "routes.charges.path",
    loadChildren: () =>
        import("./modules/charges/charges.module").then(
            (module) => module.ChargesModule
        ),
    canActivate: [RoutesGuard],
    data: CHARGES_DATA,
  },

  // login
  {
    path: "routes.login.path",
    loadChildren: () =>
        import("./modules/sign-in/sign-in.module").then(
            (module) => module.SignInModule
        ),
    canActivate: [RoutesGuard],
    data: LOGIN_DATA,
  },
  {
    path: "routes.noAccess.path",
    loadChildren: () =>
      import("./modules/no-access/no-access.module").then(
        (module) => module.NoAccessModule
      ),
    canActivate: [RoutesGuard],
    data: {
      breadcrumbName: "routes.noAccess.data.breadcrumbName",
      metas: "routes.noAccess.data.metas",
    },
  },
  {
    path: "routes.notFound.path",
    loadChildren: () =>
      import("./modules/not-found/not-found.module").then(
        (module) => module.NotFoundModule
      ),
    canActivate: [RoutesGuard],
    data: NOT_FOUND_DATA,
  },
  {
    path: "routes.notFound.anything",
    pathMatch: "full",
    loadChildren: () =>
      import("./modules/not-found/not-found.module").then(
        (module) => module.NotFoundModule
      ),
    canActivate: [RoutesGuard],
    data: NOT_FOUND_DATA,
  },
];


@NgModule({
  imports: [
    RouterModule.forRoot(Translate.translateRoutes(ROUTES), {
    relativeLinkResolution: "corrected",
    anchorScrolling: "enabled",
    paramsInheritanceStrategy: "always",
    initialNavigation: 'enabledBlocking'
}),
  ],
  providers: [],
  exports: [RouterModule],
})
export class AppRoutingModule {
  constructor(private translateService: TranslateService) {
    Translate.setService(this.translateService);
  }
}
