import {
	HttpErrorResponse,
	HttpEvent,
	HttpHandler,
	HttpInterceptor,
	HttpRequest,
	HttpResponse
} from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable, ObservableInput } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';

import { ErrorsService } from '../../errors/errors.service';
import { Store } from "@ngrx/store";
import { Authentication } from "../../authentication/authentication";
import { FormButtonService } from "../../../shared/components/default/form/button/form-button.service";
import { Auxiliary } from "../../../shared/helpers/auxiliary";

@Injectable({
	providedIn: 'root'
})
export class RequestsInterceptor implements HttpInterceptor {

	constructor(
		private errorsService: ErrorsService,
		private store: Store,
		private formButtonService: FormButtonService
	) {
	}

	intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
		let params = Auxiliary.transformParamsInSnakeCase(request.params);

		request = request.clone({
			headers: Auxiliary.mergeHeaders( Authentication.getHeaders(), request.headers),
			params,
			body: this.transformBody(request.body),
			url: Auxiliary.setRequestUrl(request.url, 'json')
		});


		this.formButtonService.addLoading(request);
		this.formButtonService.setLastClickedButton(null, true);

		return next.handle(request)
			.pipe(
				catchError((error: HttpErrorResponse): ObservableInput<any> => {
					throw error;
				}),
				tap({
					next: (response: HttpResponse<any>) => this.formButtonService.removeLoading(response),
					error: (error: HttpErrorResponse) => {
						this.formButtonService.removeLoading(error);
						this.errorsService.setErrorsByResponse(error);
					}
				})
			);
	}

	transformBody(body: any, useEntries = false) {
		const isFormData = Auxiliary.isFormData(body);

		return (isFormData && useEntries) ? this.transformInFormData(body) : Auxiliary.transformBodyInSnakeCase(body);
	}

	transformInFormData(prop: any) {
		if (Auxiliary.isFormData(prop)) {
			let toIterate = prop;

			if (!Array.isArray(toIterate)) toIterate = prop.entries;

			const newFormData = new FormData();
			for (const [name, value] of [...toIterate]) {
				if (Auxiliary.isInstanceOfFile(value))
					newFormData.append(
						Auxiliary.camelCaseToSnakeCase(name),
						value,
						Auxiliary.transformInFileName(value.name)
					);
				else newFormData.append(Auxiliary.camelCaseToSnakeCase(name), Auxiliary.transformBodyInSnakeCase(value));
			}

			return newFormData;
		}

		return prop;
	}
}
