import { Component, OnDestroy, OnInit } from '@angular/core';

import { Subscription } from "rxjs";

import { GlobalLoadingService } from "./global-loading.service";
import { Auxiliary } from "../../../helpers/auxiliary";
import { GlobalLoading } from "../../../models/global-loading";


@Component({
    selector: 'app-global-loading',
    templateUrl: './global-loading.component.html',
    styleUrls: ['./global-loading.component.scss']
})
export class GlobalLoadingComponent implements OnInit, OnDestroy{
    defaultGlobalLoading: GlobalLoading = { show: false, message: 'globalLoading.message' };
    globalLoading: GlobalLoading = Auxiliary.copy(this.defaultGlobalLoading);

    private subscriptions: Subscription[] = [];

    constructor(private globalLoadingService: GlobalLoadingService){}

    ngOnInit(): void{
        this.subscriptions.push(
            this.globalLoadingService
                .watch()
                .subscribe(globalLoading => {
                    if(!this.globalLoading.before){
                        Object.assign(this.globalLoading, this.defaultGlobalLoading, globalLoading);
                        this.globalLoadingService.setShow(this.globalLoading.show);

                        if(globalLoading.before)
                            setTimeout(() => {
                                this.globalLoading = Auxiliary.copy(this.defaultGlobalLoading);
                                this.globalLoadingService.setShow(this.globalLoading.show);
                            }, globalLoading.before);
                    }
                })
        );
    }

    ngOnDestroy(): void{
        Auxiliary.unsubscribeAll(this.subscriptions);
    }
}
