import {Menu} from '../../shared/models/menu';

export const accountAdminMenuItems: Menu[] = [
    // HOME
	{
        icon: 'account_circle',
        name: 'menu.items.account.name',
        group: 'menu.group.adm',
        path: 'routes.account.path'
    },


];
