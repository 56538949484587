import { ChangeDetectorRef, Component, Input, OnDestroy, OnInit } from '@angular/core';

import { Subscription } from "rxjs";

import { LocalActionsService } from './local-actions.service';
import { CallbackHelpers } from "../../shared/helpers/callback-helpers";
import { LocalAction, LocalActionButton } from "./interfaces/local-action";
import { Auxiliary } from "../../shared/helpers/auxiliary";
import { Generic } from "../../shared/models/generic";

@Component({
	selector: 'app-local-actions',
	templateUrl: './local-actions.component.html',
	styleUrls: ['./local-actions.component.scss']
})
export class LocalActionsComponent<T> implements LocalAction, OnInit, OnDestroy {
	@Input() where = '';
	localActions: LocalActionButton<T>[] = [];
	isTab = false;

	private subscriptions: Subscription[] = [];

	constructor(private actionsService: LocalActionsService, private changeDetectorReference: ChangeDetectorRef) {
	}

	ngOnInit(): void {
		this.subscriptions.push(
			this.actionsService
				.watchActions()
				.subscribe(actions => {
					this.setIsTab(actions);
					this.setLocalActions(actions);
					this.sendCreateAction();
					this.changeDetectorReference.detectChanges();
				})
        );
    }

    areThereLocalActions(): boolean{
        return !!this.localActions.length;
    }

    setIsTab(actions: LocalAction|Generic): void{
        this.isTab = !!actions?.isTab;
    }

    isBreacrumb(): boolean{
        return this.where === 'breadcrumb' && !this.isTab;
    }

    isTabs(): boolean{
        return this.where === 'tabs' && this.isTab;
    }

    setLocalActions(actions: LocalAction|Generic): void{
        if(this.isTabs() || this.isBreacrumb()){
            if(actions?.localActions) this.localActions = actions.localActions;
            else this.localActions = [];
        }
    }

	tooltip(action: LocalActionButton){
		return CallbackHelpers.toCallback(action?.tooltip)();
	}

    sendCreateAction(): void{
        if(!this.isBreacrumb()) return;

        let indexAction = -1;

        this.localActions.forEach((action: LocalActionButton, index: number) => action.isNew ? indexAction = index : null);

        if(indexAction >= 0) this.actionsService.sendCreateAction(this.localActions.splice(indexAction, 1)[0]);
        else this.actionsService.sendCreateAction({});
    }

    ngOnDestroy(): void{
        Auxiliary.unsubscribeAll(this.subscriptions);
    }
}
