export const CHARGES_TRANSLATE = {
    charges: {

        messages: {
            delete: "Cobrança excluída com sucesso"
        },
        list: {
            columns: {
                period: "Período de competência",
                account: "Empresa",
                professional: "Profissional"

            },


            dialogs: {
                delete: {
                    title: "Excluir cobrança",
                    body: "Você tem certeza que desejar excluir o documento referente ao período?",
                    actions: {
                        back: "Fechar",
                        confirm: "Sim, excluir"
                    }
                }
            },
            filters: {
                fields: {
                    date: {
                        startUrl: "inicio",
                        endUrl: "fim",
                        label: "Período de competência"
                    },
                    accountName: {
                        label: "Empresa",
                        placeholder: "Informe a empresa",
                        url: "empresa"
                    },
                }
            }
        },
        save: {
            form: {
                fields: {
                    company: {
                        label: "Empresa",
                        placeholder: "Selecione a empresa"
                    },
                    professional: {
                        label: "Profissional",
                        placeholder: "Selecione o profissional"
                    },
                    date: {
                        label: "Período de competência"
                    },
                    description: {
                        label: "Observação",
                        placeholder: "Informe a observação"
                    },
                    attachments: {
                        label: "Anexos",
                        noResults: {
                            label: "Nenhum anexo adicionado",
                            description: "",
                            action: {
                                label: "Adicionar anexo"
                            }
                        }
                    }
                }
            }
        }

    }
}