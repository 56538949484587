import { Directive, OnDestroy } from "@angular/core";
import { Observable, Subscription } from "rxjs";
import { Auxiliary } from "../helpers/auxiliary";

@Directive()
export class BaseSubscriber implements OnDestroy{
    protected subscriptions: Subscription[] = [];

    ngOnDestroy(): void {
        this.unsubscribeAll();
    }

    protected unsubscribeAll(){
        Auxiliary.unsubscribeAll(this.subscriptions);
    }

    protected subscribe(...observables: Observable<any>[]){
        this.subscriptions.push(...observables.map(obs => obs.subscribe()));
    }
}
