import { Injectable } from '@angular/core';
import {
	ActivatedRouteSnapshot,
	CanActivate, Router,
	RouterStateSnapshot,
	UrlTree
} from '@angular/router';

import { Observable } from 'rxjs';
import { AuthenticationService } from "../../authentication/authentication.service";
import { Authentication } from "../../authentication/authentication";
import { TranslateService } from "@ngx-translate/core";

@Injectable({
	providedIn: 'root',
})
export class RoutesGuard implements CanActivate {
	constructor(
		private authenticationService: AuthenticationService,
		private router: Router,
		private translateService: TranslateService

	) {
	}

	routesStartsWith(route: ActivatedRouteSnapshot, url: string): boolean {
		return !!route?.routeConfig?.path?.startsWith(url);
	}

	getTranslatedUrl(translation: string): string {
		let url = '';

		this.translateService.get(translation).subscribe(path => url = path.replace('/', ''));

		return url;
	}

	canActivate(
		route: ActivatedRouteSnapshot, state: RouterStateSnapshot
	): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
		const urlLogin = this.getTranslatedUrl('routes.login.path');
		const nextPath = this.getTranslatedUrl('routes.login.nextPath');
		const isLogged = Authentication.isLogged;
		const validateTokenRequest = !isLogged && Authentication.isThereAccessToken();
		const backToLogin = !isLogged && !this.routesStartsWith(route, urlLogin);

		if (validateTokenRequest) {
			this.authenticationService.validateToken(false);

			return true;
		}

		if (backToLogin) {
			this.goToLogin(urlLogin, nextPath);

			return false;
		}
		return true;
	}

	private goToLogin(loginPath: string, nextPath: string): void {
		const pathname = window.location.pathname?.trim();
		const nextUrl = pathname && pathname !== "/" ? pathname : null;
		const params = {queryParams: {[nextPath]: nextUrl}};

		this.router.navigate([loginPath], nextUrl ? params : {});
	}
}
