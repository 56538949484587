import { createSelector, MemoizedSelector } from "@ngrx/store";

import { MenuToolbarComponent } from "../../../core/menu/toolbar/menu-toolbar.component";
import { ToolbarActions } from "../../models/toolbar-actions";

// const getAction = (currentUser?: User): ToolbarAction => {
//
// 	return {
// 		condition: () => false,
// 	};
// };

// export const selectActions = createSelector(
// 	userSelectors.selectCurrentUser,
// 	userSelectors.selectToolbarTools,
// 	(user, tools) =>
// 		tools.map(tool => getAction(tool, user))
// );

export const selectToolbarActions = (component: MenuToolbarComponent): MemoizedSelector<unknown, ToolbarActions> => createSelector(
    (): ToolbarActions => ({
        left: [
            {
                translationParams: {
                    projectName: component.projectName
                },
                show: true,
                text: "menu.toolbar.actions.logout.text",
                tooltip: "menu.toolbar.actions.logout.text",
                theme: "icon",
                icon: 'login',
                color: "none",
                click: () => {
                    component.signInService.logout()
                }
            },
        ],
        right: []
    })
);
