import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Params, QueryParamsHandling, Router, RoutesRecognized } from '@angular/router';

import { BehaviorSubject, Observable, of, Subject } from 'rxjs';
import { take } from 'rxjs/operators';

import { environment } from '../../../environments/environment';
import { Menu } from '../../shared/models/menu';
import { accountAdminMenuItems } from './menu-items-list';
import { NOTEBOOK_BREAKPOINT } from "../../shared/default-variables/breakpoints";
import { Translate } from "../../shared/helpers/translate";
import { Auxiliary } from "../../shared/helpers/auxiliary";

@Injectable({
    providedIn: "root",
})
export class MenuService {
    watchListSetted = new BehaviorSubject<Menu[]>([]);
    reorderMenu = new Subject<boolean>();
    toggle = new Subject<boolean>();
    close = new Subject<boolean>();
    toggleToolListSubject = new Subject<boolean>();

    private menuList: Menu[] = this.translateMenuList(accountAdminMenuItems);
    private activatedItem: Menu = {} as Menu;
    private menuLoading = false;
    private limit = NOTEBOOK_BREAKPOINT;

    constructor(private router: Router, private http: HttpClient) {
        this.setActivatedItem(this.findActivatedItem(this.router.url));

        this.router.events.subscribe(routerEvent => {
            if(routerEvent instanceof RoutesRecognized) this.setActivatedItem(this.findActivatedItem(routerEvent.urlAfterRedirects));
        });
    }

    translateMenuList(menuList: Menu[]): Menu[]{
        return menuList.map(item => ({ ...item, path: Translate.value(item.path) }));
    }

    setActivatedItem(item: Menu): void{
        if(item) this.activatedItem = item;
    }

    findItem(first: boolean = false): Menu{
        const firstItem: null|Menu = first || location.pathname === '/' ? this.menuList[0] : null;
        const item: Menu = this.menuList.filter((route: Menu) => location.pathname.includes(route.path))[0];

        return firstItem || item;
    }

    chooseHandling(path: string): Observable<QueryParamsHandling>{
        return of(this.router.url.replace(/\?.+/, '') === path ? 'preserve' : '');
    }

    isBiggerThanLimit(width: number = window.innerWidth): boolean{
        return width < this.limit;
    }


    get list(): Menu[]{
        return this.menuList;
    }

    set list(menuList: Menu[]){
        this.menuList = [...menuList, ...accountAdminMenuItems];
        this.watchListSetted.next(this.menuList);
    }

    get loading(): boolean{
        return this.menuLoading;
    }

    setLoading(loading: boolean, timeout = 0){
        setTimeout(() => {
			this.menuLoading = loading;
		}, timeout);
    }

    toggleToolList(open: boolean){
        this.toggleToolListSubject.next(open);
    }

    toolbarActions(params: Params): Observable<Params>{

        return this.http.get<Params>(`${environment.api}/tools`, { params: Auxiliary.createHttpParams(params) }).pipe(take(1));
    }
    private findActivatedItem(url: string): Menu{
        return this.menuList.filter((item: Menu) => url.includes(item.path))[0];
    }


}
