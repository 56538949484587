import { Injectable } from '@angular/core';
import { ActivatedRoute, Params } from "@angular/router";
import { FormBuilder, FormGroup } from "@angular/forms";

import { BehaviorSubject, Observable, Subject } from 'rxjs';

import { LocalActionsService } from "../local-actions/local-actions.service";
import { map, pluck } from "rxjs/operators";
import { Filter } from "./interfaces/filter";
import { LocalAction } from "../local-actions/interfaces/local-action";
import { Translate } from "../../shared/helpers/translate";
import { FilterField } from "./interfaces/filter-field";
import { Generic } from "../../shared/models/generic";
import { cast } from "../../shared/rxjs-operators";

@Injectable({
    providedIn: 'root'
})
export class FiltersService{
    filter$: Observable<Filter>;
    canShowFilter$: Observable<boolean>;
    onSearch$: Observable<null>;
    onClearAll$: Observable<null>;

    private _filter$ = new BehaviorSubject<Filter>({
		form: this.fb.group({}),
		fields: [],
		component: {}
	});

    private toggleSubject = new Subject<any>();
    private _search = new Subject<null>();
    private _clearAll = new Subject<null>();
    private closeSubject = new Subject<any>();
    private activesBehaviorSubject = new BehaviorSubject<number>(0);


    constructor(
		private activatedRoute: ActivatedRoute,
		private localActions: LocalActionsService,
		private fb: FormBuilder,
	){
		this.canShowFilter$ = this.localActions.watchActions().pipe(
			cast<LocalAction>(),
			pluck('localActions'),
			map(actions => !!actions?.find(filter => filter.icon === "filter_list")),
		);

		this.filter$ = this._filter$.asObservable();
		this.onSearch$ = this._search.asObservable();
		this.onClearAll$ = this._clearAll.asObservable();
	}

    watchFilters(): Observable<Filter>{
        return this._filter$.asObservable();
    }

    sendFilters(filters: Filter): void{
        this._filter$.next(filters);
    }

    onToggle(): Observable<any>{
        return this.toggleSubject.asObservable();
    }

    open(): void{
        this.toggleSubject.next(null);
    }

    onClose(): Observable<any>{
        return this.closeSubject.asObservable();
    }

    close(): void{
        this.closeSubject.next(null);
    }

    watchActives(): Observable<number>{
        return this.activesBehaviorSubject.asObservable();
    }

	search(){
		this._search.next(null);
	}

	clearAll(){
		this._clearAll.next(null);
	}

    sendActives(actives: number): void{
        this.activesBehaviorSubject.next(actives);
    }

    removeFiltersFields(parameters: Params, form: FormGroup): Params{
        const newParameters: Params = {};
        const fieldKeys = Object.keys(form.getRawValue());

        for(const parameter in parameters)
            if(parameters.hasOwnProperty(parameter) && !fieldKeys.includes(parameter))
                newParameters[parameter] = parameters[parameter];

        return newParameters;
    }

    getFiltersOnURL(fields: FilterField[]): Params{
        const filters: Params = {};
        const filtersUrlNames: Generic[] = [];

        fields.forEach(field => {
            if(field.startUrlParameter) filtersUrlNames.push({ name: field.startName, urlParameter: field.startUrlParameter });
            if(field.endUrlParameter) filtersUrlNames.push({ name: field.endName, urlParameter: field.endUrlParameter });
            if(field.urlParameter) filtersUrlNames.push({ name: field.name, urlParameter: field.urlParameter });
        });

        this.activatedRoute
            .queryParams
            .subscribe(parameters =>
                filtersUrlNames.forEach(filter => {
					const key = Translate.value(filter.urlParameter);
                    const value = parameters[key];
                    if(value) filters[filter.name] = value;
                })
            );

        return filters;
    }
}
