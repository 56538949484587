import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormControlPipe } from "./form-control.pipe";
import { IncludesPipe } from './includes.pipe';
import { ControlErrorPipe } from "./control-error.pipe";


@NgModule({
    declarations: [FormControlPipe, IncludesPipe, ControlErrorPipe],
    imports: [
        CommonModule
    ],
    exports: [FormControlPipe, IncludesPipe, ControlErrorPipe]
})
export class FormPipeModule {
}
